import useFetchOrderById from '@/api/queries/getOrderById';
import useFetchSubscriptions from '@/api/queries/getSubscriptions';
import useFetchUserDetails from '@/api/queries/getUserDetails';
import { lastSubscriptionOrder, transformSubscription } from '@/api/utils';
import { PinsaSubscription } from '@/types/pinsaTypes';
import { Envs, PricingPolicyConfig, Subscription } from '@/types/types';
import { endOfTheDay } from '@/utils/date';
import dayjs from 'dayjs';
import { camelizeKeys } from 'humps';
import { load } from 'js-yaml';
import { defineStore } from 'pinia';

import settingsContent from '@/assets/content/general_settings.yml?raw';

export const useUserStore = defineStore('userStore', () => {
  const { data, refetch: getUserDetails } = useFetchUserDetails();

  const attributes = computed(() => camelizeKeys(data.value?.data?.attributes));

  const email = computed(() => attributes.value?.email);
  const fullName = computed(() => `${attributes.value?.firstName} ${attributes.value?.lastName}`);
  const userId: ComputedRef<string | undefined> = computed(() => attributes.value?.extId);
  const phone = computed(() => attributes.value?.phone);
  const acceptsFreeGifts = computed(() => attributes.value?.acceptsFreeGifts);
  const defaultAddressId = computed(() => data.value?.data?.relationships?.default_address?.data?.id);

  const { subscriptions: subs, selectedSubscription, getUserSubscriptions } = useFetchSubscriptions();

  const subscriptions = computed(() => subs.value?.map((sub: PinsaSubscription) => sub.attributes));
  const hasSubscription = computed(() => subscription.value && subscriptions.value?.length > 0);
  const lastOrderId = computed(() => selectedSubscription.value?.data.relationships?.last_order?.data?.id);

  const isAfterCutoff = computed(() => dayjs() >= endOfTheDay(subscription.value?.cutoffTime));

  const subscriptionSellingPlanId = computed(
    () =>
      subscription.value?.lineItems?.find(line => line.pricingPolicy?.selling_plan_id && line.productType === 'Press')?.pricingPolicy
        ?.selling_plan_id
  );

  const isAbleToPauseOrCancel = computed(() => {
    const settings = load(settingsContent) as PricingPolicyConfig;
    const sellingPlanId = settings[import.meta.env.VITE_ENV as Envs]?.selling_plan_id;

    const isSubsValid =
      subscription.value?.state !== 'cancelled' && !isAfterCutoff.value && subscription.value?.trackingInformation?.state !== 'processing';

    return subscriptionSellingPlanId.value == sellingPlanId
      ? isSubsValid &&
          ((subscription.value?.ordersCount === 2 && lastOrder.value?.state === 'shipped') || (subscription.value?.ordersCount ?? 0) > 2)
      : isSubsValid;
  });

  const { data: order } = useFetchOrderById(lastOrderId);

  const lastOrder = computed(() => {
    if (!order.value) return undefined;
    return lastSubscriptionOrder(order.value?.data);
  });

  const subscription: Ref<Subscription | undefined> = computed(() => {
    return transformSubscription(selectedSubscription.value);
  });

  return {
    email,
    fullName,
    userId,
    phone,
    hasSubscription,
    acceptsFreeGifts,
    attributes,
    defaultAddressId,
    subscriptions,
    subscription,
    lastOrder,
    isAbleToPauseOrCancel,
    isAfterCutoff,
    subscriptionSellingPlanId,
    getUserSubscriptions,
    getUserDetails,
  };
});
