import { FingerprintJSPro, fpjsPlugin } from '@fingerprintjs/fingerprintjs-pro-vue-v3';
import * as Sentry from '@sentry/vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import App from './App.vue';
import './index.css';
import router from './router';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(VueQueryPlugin);
app.use(fpjsPlugin, {
  loadOptions: {
    apiKey: import.meta.env.VITE_FINGERPRINT_JS_KEY,
    endpoint: ['https://metrics.pinter.com', FingerprintJSPro.defaultEndpoint],
    scriptUrlPattern: [
      'https://metrics.pinter.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
      FingerprintJSPro.defaultScriptUrlPattern,
    ],
  },
});

if (import.meta.env.VITE_ENV === 'production')
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'barley.pinter.com', 'shop.pinter.com'],
    // Session Replay
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
  });

/**
  When we do a release customers that were using the website
  will have a old version of the routes, causing it to 404 if
  the route was never fetched.
  To fix this we need to force the users to reload the page
  and download the new assets.
 */
router.onError(err => {
  if (err instanceof TypeError && err?.message?.startsWith('Failed to fetch dynamically imported module')) {
    Promise.resolve(err);
    // Fully reload page so we can fetch the new routes
    location.reload();
  }
});

app.mount('#app');
