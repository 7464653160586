import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';

export const createDeliveryDateFormatted = (
  date?: string | number | Date | dayjs.Dayjs,
  format = 'dddd Do MMM'
) => {
  dayjs.extend(AdvancedFormat);
  return dayjs(date).format(format);
};

export const isWeekInFuture = (date: string | Date) => {
  dayjs.extend(isoWeek);
  const d1 = dayjs(date).startOf('isoWeek');
  const d2 = dayjs(new Date()).startOf('isoWeek');
  return d1.isAfter(d2, 'week');
};

interface WeekDayParams {
  value: string | Date;
  isShortForm?: boolean;
}

export const getWeekDay = ({ value, isShortForm }: WeekDayParams) => {
  const format = isShortForm ? 'ddd' : 'dddd';
  return dayjs(value).format(format);
};

export const endOfTheDay = (date?: string | Date | null) => {
  if (!date) return '';
  dayjs.extend(utc);
  return dayjs(date).utc().endOf('day');
};

export const formatDate = (date?: string | Date | null) => {
  if (!date) return '';
  dayjs.extend(AdvancedFormat);
  dayjs.extend(utc);
  dayjs.locale('en');
  return dayjs(date).utc().format('MMMM Do YYYY');
};
