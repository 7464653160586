import { CollectionByHandleQuery, Metafield, Product as ShopifyProduct } from '@/types/shopify.type';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';
import { MutationObserverOptions } from '@tanstack/vue-query';
import {
  PinsaAddressIncluded,
  PinsaLineItem,
  PinsaOrderResponse,
  PinsaProductIncluded,
  PinsaShipment,
  PinsaSubscription,
  PinsaSubscriptionIncluded,
  PinsaSubscriptionLineIncluded,
  PinsaSubscriptionResponse,
  PinsaVariantIncluded,
  Relationship,
} from '@/types/pinsaTypes';
import { Address, LineItem, ShippedOrder, Subscription, SubscriptionVariant, Product } from '@/types/types';

interface ProductType {
  data: {
    product_type: string;
  };
}

export const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true,
  headers: {},
});

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data && response.headers['content-type']?.includes('application/json')) {
      // response.data = camelizeKeys(response.data as any);
    }
    return response;
  },
  error => {
    // if (error.response?.status === 401) {
    //   currentUserStore().$reset();
    // } else if (isAxiosError<{ message: string }>(error)) {
    //   trackCustomEvent('error_reported', {
    //     error_type: error.response?.data?.message,
    //   });
    // }
    throw error;
  }
);

export const getSpreadPaymentType = (): Promise<ProductType> => {
  return Axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/settings/spread_payments_product_type`);
};

export const transformCollections = (products: CollectionByHandleQuery['collectionByHandle']): Product[] => {
  return products
    ? products.products.edges.map(product => {
        const newProduct = product.node;
        const variants = newProduct?.variants.edges.map(variant => variant.node);
        const collections = newProduct?.collections.edges.map(collection => collection.node);
        const images = newProduct?.media.edges.map(image => image.node);
        const sellingPlanGroups = newProduct.sellingPlanGroups.edges
          .map(sellingPlanGroup => sellingPlanGroup.node)
          .map(sellingPlanGroup => {
            const sellingPlans = sellingPlanGroup.sellingPlans.edges.map(sellingPlan => sellingPlan.node);
            return { ...sellingPlanGroup, sellingPlans: sellingPlans };
          });
        return {
          ...newProduct,
          variants: variants,
          collections: collections,
          media: images,
          metafields: [],
          sellingPlanGroups: sellingPlanGroups,
        };
      })
    : [];
};

export const transformProduct = (node: ShopifyProduct) => {
  const variants = node?.variants.edges.map(({ node }) => node);
  const collections = node?.collections.edges.map(({ node }) => node);
  const images = node?.media.edges.map(({ node }) => node);
  const sellingPlanGroups = node.sellingPlanGroups.edges.map(({ node }) => {
    const sellingPlans = node.sellingPlans.edges.map(({ node }) => node);
    return { ...node, sellingPlans };
  });
  return {
    ...node,
    variants,
    collections,
    media: images,
    metafields: [] as Metafield[],
    sellingPlanGroups,
  };
};

export const transformSubscription = (subsResult?: PinsaSubscriptionResponse) => {
  if (subsResult) {
    const subscriptionRelated = subsResult?.included;

    if ((subscriptionRelated ?? []).length > 0) {
      const currentSubscription = subsResult.data as PinsaSubscription;
      const lineItems = subscriptionRelated.filter(
        (element: PinsaSubscriptionIncluded) => element.type === 'subscription_line'
      ) as PinsaSubscriptionLineIncluded[];

      const lineItemsInfo: LineItem[] = lineItems.reduce((acc: LineItem[], lineItem: PinsaSubscriptionLineIncluded) => {
        const variant = subscriptionRelated.find(
          (element: PinsaSubscriptionIncluded) => element.type === 'variant' && lineItem.relationships?.variant?.data?.id === element?.id
        ) as PinsaVariantIncluded | undefined;

        const prod = subscriptionRelated.find(
          (element: PinsaSubscriptionIncluded) => element.type === 'product' && element.id === lineItem.relationships.product.data.id
        ) as PinsaProductIncluded | undefined;
        if (!variant || !prod) return acc;
        const line: LineItem = {
          ...(camelizeKeys(lineItem?.attributes) as LineItem),
          extId: prod?.attributes?.ext_id,
          name: prod?.attributes?.title,
          productType: prod?.attributes?.product_type,
          pricingPolicy: lineItem?.attributes?.pricing_policy,
          id: lineItem?.id,
          variant: {
            ...(camelizeKeys(variant?.attributes) as SubscriptionVariant),
            id: variant.id,
            name: variant.attributes?.full_title,
          } as SubscriptionVariant,
        };
        acc.push(line);
        return acc;
      }, []);

      const address = subscriptionRelated.find((element: PinsaSubscriptionIncluded) => element.type === 'address') as
        | PinsaAddressIncluded
        | undefined;

      const subscription: Subscription = {
        ...(camelizeKeys(currentSubscription?.attributes) as Subscription),
        extId: currentSubscription?.attributes?.ext_id,
        id: currentSubscription?.id,
        unpauseAt: currentSubscription?.attributes?.next_billing_date,
        lineItems: lineItemsInfo,
        address: {
          ...(camelizeKeys(address?.attributes) as Address),
          id: address?.id,
        },
        paymentMethodId: currentSubscription?.relationships?.customer_payment_method.data?.id,
        pricing_plan: currentSubscription?.relationships?.pricing_plan?.data?.id,
      };
      return subscription;
    }
  }
};

const getOrderLineItemsFromPayload = (order: PinsaOrderResponse) => {
  return order.data.relationships.line_items.data.map((lineItem: Relationship) => {
    const prod = order.included.find((element: Relationship) => element.type === 'line_item' && element.id === lineItem.id);
    return prod as PinsaLineItem;
  });
};

export const lastSubscriptionOrder = (order_payload: PinsaOrderResponse) => {
  const order = order_payload.data;
  if (order) {
    const shipment = order_payload.included.find(element => element.type === 'shipment') as PinsaShipment;
    const lastOrder: ShippedOrder = {
      ...(camelizeKeys(order?.attributes) as ShippedOrder),
      subtotal: order?.attributes?.subtotal_price,
      total: order?.attributes?.total_price,
      discount: order?.attributes?.total_discounts,
      lineItemsTotal: order?.attributes?.total_line_items_price,
      lineItems: getOrderLineItemsFromPayload(order_payload),
      deliveryPriceAmount: shipment?.attributes?.total || '0',
    };
    return lastOrder;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<ReturnType<FnType>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MutationConfig<MutationFnType extends (...args: any) => any> = MutationObserverOptions<
  ExtractFnReturnType<MutationFnType>,
  AxiosError,
  Parameters<MutationFnType>[0]
>;
